<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <b-form>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group
            label="Unterhaltsberechnung (empfohlen)"
            label-for="mitUnterhaltsberechnung"
          >
            <b-form-checkbox
              v-model="data.MitUnterhalt"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col v-show="data.Art !== 2" cols="12" md="3">
          <b-form-group
            :label="data.Art === 0 ? 'Trennungsunterhalt' : 'Nachehelicher Unterhalt'"
            label-for="mitNachehelichemUnterhalt"
          >
            <b-form-checkbox
              v-model="data.MitUnterhaltNachEhe"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col v-show="data.MitUnterhaltNachEhe" cols="12" md="3">
          <b-form-group
            :label="'Anspruch Unterhalt ' + $root.VNameFrau"
            label-for="vermutungAnspruchFrau"
          >
            <b-form-checkbox
              v-model="data.AnspruchElternID"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col v-show="!data.MitUnterhalt && data.MitUnterhaltNachEhe" cols="12" md="3">
          <b-form-group
            label="Manko vorhanden?"
            label-for="istManko"
          >
            <b-form-checkbox
              v-model="data.IstManko"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-show="data.MitUnterhalt">
        <b-col cols="12" md="3">
          <b-form-group
            label="Automatische Steuerberechnung"
            label-for="automatischeSteuer"
          >
            <b-form-checkbox
              v-model="data.IstSteuer"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col v-show="data.IstSteuer && data.Art !== 2" cols="12" md="3">
          <b-form-group
            label="Mit Übergang Veranlagung"
            label-for="uebergangVeranlagung"
          >
            <b-form-checkbox
              v-model="data.MitUebergangVeranlagung"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col v-show="data.IstSteuer && data.Art !== 2" cols="12" md="3">
          <b-form-group
            label="Mit Steuerabschluss"
            label-for="steuerAbschluss"
          >
            <b-form-checkbox
              v-model="data.MitAbschlussSteuer"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12" md="4">
          <b-form-group
            label="Überschussverteilung % maximal von Grundbedarfen"
            label-for="UeberschussMax"
          >
            <vue-slider
              v-model="data.UeberschussProzMax"
              :min="5"
              :max="50"
              :tooltip="'always'"
              :tooltip-placement="'right'"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="data.Art === 2" cols="12" md="4">
          <b-form-group
            :label="'Überschussanteil Kind' + (data.AnzahlKinder > 1 ? 'er wie ' : ' wie ') + (data.AnspruchElternID ? $root.VNameMann : $root.VNameFrau)"
            label-for="ueberschussKindWieUP"
          >
            <b-form-checkbox
              v-model="data.UeberschussKindWieUP"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row v-show="data.MitUnterhalt">
        <b-col cols="12" md="3">
          <b-form-group
            label="Überschussverteilung nach Sparquote"
            label-for="ueberschussProz"
          >
            <b-form-checkbox
              v-model="ueberschussMax"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="setUeberschussMax"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="!ueberschussMax" cols="12" md="4">
          <b-form-group
            label="Überschussverteilung % maximal von Grundbedarfen"
            label-for="UeberschussMax"
          >
            <vue-slider
              v-model="data.UeberschussProzMax"
              :min="5"
              :max="50"
              :tooltip="'always'"
              :tooltip-placement="'right'"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="ueberschussMax" cols="12" md="3">
            <b-form-group
              :label="'Sparquote ' + (data.AnspruchElternID ? $root.VNameMann : $root.VNameFrau)"
              label-for="sparquoteUP"
            >
            <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right"
              v-model="data.SparquoteManuellUP"
              @blur="applyFormat($event, 'data.SparquoteManuellUP')"
              @focus="removeFormat"
            />
            </b-form-group>
          </b-col>

          <b-col v-show="ueberschussMax" cols="12" md="3">
            <b-form-group
              :label="'Sparquote ' + (data.AnspruchElternID ? $root.VNameFrau : $root.VNameMann)"
              label-for="sparquoteUB"
            >
            <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right"
              v-model="data.SparquoteManuellUB"
              @blur="applyFormat($event, 'data.SparquoteManuellUB')"
              @focus="removeFormat"
            />
            </b-form-group>
          </b-col>

        <b-col v-show="data.Art === 2" cols="12" md="3">
          <b-form-group
            :label="'Überschussanteil Kind' + (data.AnzahlKinder > 1 ? 'er wie ' : ' wie ') + (data.AnspruchElternID ? $root.VNameMann : $root.VNameFrau)"
            label-for="ueberschussKindWieUP"
          >
            <b-form-checkbox
              v-model="data.UeberschussKindWieUP"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-row v-show="!data.MitUnterhalt || data.GeteilteObhut"> -->
      <b-row v-show="!data.MitUnterhalt">
        <b-col v-show="data.AnzahlKinder > 0" cols="12" md="6">
          <b-form-group
            :label="'Unterhalt Kind' + (data.AnzahlKinder > 1 ? 'er ' : ' ') + '- Phasen, ggf. Kostenaufteilung bspw. bei alternierender Obhut, Manko, etc.)'"
            label-for="wochenende"
          >
            <b-form-textarea
              v-model="data.UnterhaltKind"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="!data.MitUnterhalt && data.MitUnterhaltNachEhe && data.Art !== 2" cols="12" md="6">
          <b-form-group
            :label="data.Art === 0 ? 'Regelung Trennungsunterhalt' : 'Regelung nachehelicher Unterhalt'"
            label-for="wochenende"
          >
            <b-form-textarea
              v-model="data.UnterhaltNachEhe"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-show="!data.MitUnterhalt && data.MitUnterhaltNachEhe">
        <b-col cols="12" md="6">
          <b-form-group
            :label="'Grundlage Unterhaltsberechnung: Mittel ' + $root.VNameFrau"
            label-for="mittelFrau"
          >
            <b-form-textarea
              v-model="data.MittelFrau"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="'Grundlage Unterhaltsberechnung: Mittel ' + $root.VNameMann"
            label-for="mittelMann"
          >
            <b-form-textarea
              v-model="data.MittelMann"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-show="!data.MitUnterhalt && data.AnzahlKinder > 0">
        <b-col cols="12" md="6">
          <b-form-group
            :label="'Grundlage Unterhaltsberechnung: Name' + (data.AnzahlKinder > 1 ? 'n Kinder' : ' Kind')"
            label-for="nameKind"
          >
            <b-form-textarea
              v-model="data.NameKind"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="'Grundlage Unterhaltsberechnung: Mittel Kind' + (data.AnzahlKinder > 1 ? 'er' : '')"
            label-for="mittelKind"
          >
            <b-form-textarea
              v-model="data.MittelKind"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-show="data.Art === 1">
        <b-col v-show="data.AnzahlKinder > 0" cols="12" md="6">
          <b-form-group
            :label="'Info: Unterhaltregelung Kind' + (data.AnzahlKinder > 1 ? 'er ' : ' ') + 'während Trennung'"
            label-for="wochenende"
          >
            <b-form-textarea
              v-model="data.UnterhaltKindTrennung"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Info: Unterhaltsregelung Ehegatte während Trennung"
            label-for="wochenende"
          >
            <b-form-textarea
              v-model="data.UnterhaltPartnerTrennung"
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save_changes(true)"
    >
      Speichern
    </b-button>
    <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
  </div>
</template>

<script>
import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import dos from '@/service/dossier'
import ben from '@/service/benutzer'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    VueSlider,
  },
  data() {
    return {
      data: null,
      ueberschussMax: false,
      isEditing: false,
      art: [],
      required,
    }
  },
  mounted() {
    this.$root.$refs.UNTERHALT = this
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      const r = await dos.getDossier(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data[0]
        this.data.AnspruchElternID = this.data.AnspruchElternID === 2
        this.ueberschussMax = this.data.UeberschussProzMax === 0
      }
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, value) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      const parts = value.split('.')
      if (parts.length === 2) this[parts[0]][parts[1]] = event.target.value // eslint-disable-line
      else this[parts[0]] = event.target.value // eslint-disable-line
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async setUeberschussMax() {
      this.data.UeberschussProzMax = this.ueberschussMax ? 0 : 20
      this.data.SparquoteManuellUP = 0
      this.data.SparquoteManuellUB = 0
    },
    async save_changes(showToast) {
      await dos.putDossier(this.data)

      const a = await ben.getBenutzerAbilities(this.$g.user.role, this.$root.currDosID)
      if (a.data !== null) {
        const ud = JSON.parse(localStorage.getItem('userData'))
        ud.Ability = a.data
        localStorage.setItem('userData', JSON.stringify(ud))
        this.$ability.update(JSON.parse(ud.Ability))
      }

      if (showToast) this.$root.showToast('Speichern', 'Grunddaten Unterhalt erfolgreich gespeichert!')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
