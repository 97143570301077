<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <validation-observer #default="{ invalid }">
      <b-form>

        <b-row>
          <!-- <b-col cols="12" md="4">
            <b-form-group
              label="Pensumregel"
              label-for="pensumregel"
            >
              <v-select
                v-model="data.Ist1016"
                :options="pensumregel"
                :reduce="val => val.value"
                :clearable="false"
                input-id="PensumRegel"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col v-show="data.Ist1016" cols="12" md="4">
            <b-form-group
              label="Pensum % 10 - 16 jährig (30% - 60%)"
              label-for="Proz1016"
            >
              <vue-slider
                v-model="data.Proz1016"
                :min="20"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'bottom'"
              />
            </b-form-group>
          </b-col>
 -->
          <b-col v-show="!data.Ist1016 && (data.AnspruchElternID === 1 ? data.PensumEM < 50 : data.PensumEF < 50)" cols="12" md="4">
            <b-form-group
              :label="'Pensum ' + (data.AnspruchElternID === 1 ? $root.VNameMann : $root.VNameFrau) + ' Einschulung bis 12-jährig (50%)'"
              label-for="Proz612"
            >
              <vue-slider
                v-model="data.Proz612"
                :min="20"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>

          <b-col v-show="!data.Ist1016 && (data.AnspruchElternID === 1 ? data.PensumEM < 80 : data.PensumEF < 80)" cols="12" md="4">
            <b-form-group
              :label="'Pensum ' + (data.AnspruchElternID === 1 ? $root.VNameMann : $root.VNameFrau) + ' 12 bis 16-jährig (80%)'"
              label-for="Proz1216"
            >
              <vue-slider
                v-model="data.Proz1216"
                :min="20"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
              :label="'Anteil % ' + (data.AnspruchElternID === 1 ? $root.VNameFrau : $root.VNameMann) + ' ausserordentliche Kosten'"
              label-for="UngedeckteKostenVater"
            >
              <vue-slider
                v-model="data.KostenProzUngedecktVater"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Erziehungsgutschriften geteilt"
              label-for="erzgut"
            >
              <b-form-checkbox
                v-model="erziehungsgutschriftGeteilt"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col v-show="!erziehungsgutschriftGeteilt" cols="12" md="4">
            <b-form-group
              label="Erziehungsgutschriften Mutter"
              label-for="erzgutmut"
            >
              <b-form-checkbox
                v-model="erziehungsgutschriftMutter"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Obhut"
              label-for="obhutplan"
            >
              <v-select
                v-model="data.Obhutplan"
                :options="obhutplan"
                :reduce="val => val.value"
                :clearable="false"
                input-id="Obhut"
                @input="setObhutplan"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" v-show="data.Obhutplan === 'AOSP' || data.Obhutplan === 'GOSP'">
            <b-form-group
              :label="'Betreuungsplan ' + $root.VNameMann"
              label-for="obhutplanVater"
            >
              <b-form-checkbox
                v-model="data.IstObhutplanVater"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col v-show="1 === 2" cols="12" md="2">
            <b-form-group
              label="Fremdbetreuung"
              label-for="fremdbetreuung"
            >
              <b-form-checkbox
                v-model="data.IstFremdbetreut"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="12" md="4">
            <b-form-group
              label="Alternierende Obhut"
              label-for="alternierendeObhut"
            >
              <b-form-checkbox
                v-model="data.GeteilteObhut"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Fremdbetreuung"
              label-for="fremdbetreuung"
            >
              <b-form-checkbox
                v-model="data.IstFremdbetreut"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>-->
        <b-row>
          <!-- <b-col v-show="data.GeteilteObhut" cols="12" md="4">
            <b-form-group
              label="Mit Betreuungszeitbereichen"
              label-for="mitBetreuungZB"
            >
              <b-form-checkbox
                v-model="data.MitBetreuungZeitbereichen"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col> -->
          <b-col v-show="data.Obhutplan !== 'AOSP'" cols="12" md="4">
            <b-form-group
              :label="'Betreuung % ' + $root.VNameMann"
              label-for="BetreuungProzEM"
            >
              <vue-slider
                v-model="data.BetreuungProzEM"
                :min="15"
                :max="75"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Wohnanteil Kind pauschal"
              label-for="WohnKindPauschal"
            >
              <b-form-checkbox
                v-model="WohnKindPauschal"
                class="custom-control-primary"
                name="check-button"
                @change="setWohnKindPauschal"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="WohnKindPauschal" cols="12" md="4">
            <b-form-group
              label='Wohnanteil Pauschale'
              label-for="wohnKindlPauschale"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="data.WohnKindPauschal"
                @blur="applyFormat($event)"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col v-show="data.GeteilteObhut" cols="12" md="4">
            <b-form-group
              label="Mit Betreuungsplan"
              label-for="mitBetreuungPlan"
            >
              <b-form-checkbox
                v-model="data.MitBetreuungZeitPlan"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row v-show="data.GeteilteObhut && data.MitBetreuungZeitbereichen && data.BetreuungBis1 !== null">
          <b-col cols="12" md="4">
            <b-form-group
              label="Betreuung Zeitbereich 1 bis"
              label-for="BZB1"
            >
              <flat-pickr
                v-model="data.BetreuungBis1"
                :config="config"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Betreuung % Mutter"
              label-for="BetreuungBis1M"
            >
              <vue-slider
                v-model="data.BetreuungBis1M"
                :min="0"
                :max="100 - data.BetreuungBis1V"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              :label="'Betreuung % ' + $root.VNameMann"
              label-for="BetreuungBis1V"
            >
              <vue-slider
                v-model="data.BetreuungBis1V"
                :min="0"
                :max="100 - data.BetreuungBis1M"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="data.GeteilteObhut && data.MitBetreuungZeitbereichen && data.BetreuungBis2 !== null">
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="BetreuungZeitbereich2"
              :rules="{ dateGreater: [data.BetreuungBis1] }"
            >
              <b-form-group
                label="Betreuung Zeitbereich 2 bis"
                label-for="BZB2"
              >
                <flat-pickr
                  v-model="data.BetreuungBis2"
                  :config="config"
                  class="form-control"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Betreuungbis 1...' : 'Betreuungbis 2 muss nach BetreuungsBis 1 sein!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Betreuung % Mutter"
              label-for="BetreuungBis2M"
            >
              <vue-slider
                v-model="data.BetreuungBis2M"
                :min="0"
                :max="100 - data.BetreuungBis2V"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              :label="'Betreuung % ' + $root.VNameMann"
              label-for="BetreuungBis2V"
            >
              <vue-slider
                v-model="data.BetreuungBis2V"
                :min="0"
                :max="100 - data.BetreuungBis2M"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="data.GeteilteObhut && data.MitBetreuungZeitbereichen && data.BetreuungBis3 !== null">
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="BetreuungZeitbereich3"
              :rules="{ dateGreater: [data.BetreuungBis2] }"
            >
              <b-form-group
                label="Betreuung Zeitbereich 3 bis"
                label-for="BZB3"
              >
                <flat-pickr
                  v-model="data.BetreuungBis3"
                  :config="config"
                  class="form-control"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Betreuungbis 1...' : 'Betreuungbis 3 muss nach BetreuungsBis 2 sein!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Betreuung % Mutter"
              label-for="BetreuungBis3M"
            >
              <vue-slider
                v-model="data.BetreuungBis3M"
                :min="0"
                :max="100 - data.BetreuungBis3V"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              :label="'Betreuung % ' + $root.VNameMann"
              label-for="BetreuungBis3V"
            >
              <vue-slider
                v-model="data.BetreuungBis3V"
                :min="0"
                :max="100 - data.BetreuungBis3M"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="data.Obhutplan === 'AOSP' || data.Obhutplan === 'GOSP'">
          <b-col cols="12" md="6">
            <b-form-group
              label="Wochenende"
              label-for="wochenende"
            >
              <b-form-textarea
                v-model="data.Wochenende"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Wochentags"
              label-for="wochentags"
            >
              <b-form-textarea
                v-model="data.Wochentags"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Ferien (...für die Dauer von...)"
              label-for="ferien"
            >
              <b-form-textarea
                v-model="data.Ferien"
                rows="3"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col v-show="!data.GeteilteObhut" cols="12" md="6">
            <b-form-group
              label="Feiertage (Das Kind, bzw. die Kinder verbringen...)"
              label-for="feiertage"
            >
              <b-form-textarea
                v-model="data.Feiertage"
                rows="3"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col v-show="data.GeteilteObhut"  cols="12" md="6">
            <b-form-group
              :label="'Feiertage (' + $root.VNameMann + ' betreut...)'"
              label-for="feiertage"
            >
              <b-form-textarea
                v-model="data.FeiertageAltObhut"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Holen und Bringen"
              label-for="holen-bringen"
            >
              <b-form-textarea
                v-model="data.HolenBringen"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="save_changes(true)"
      >
        Speichern
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormCheckbox, BForm, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VueSlider from 'vue-slider-component'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import dos from '@/service/dossier'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    flatPickr,
    vSelect,
    VueSlider,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  data() {
    return {
      data: null,
      isEditing: false,
      WohnKindPauschal: false,
      erziehungsgutschriftGeteilt: true,
      erziehungsgutschriftMutter: false,
      required,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  mounted() {
    this.$root.$refs.KINDBELANGE = this // reload data when saving possibly changed data in Paar for Pensum...

    extend('dateGreater', {
      async validate(value, p) {
        if (p[0] <= value) return true
        return 'Datum kleiner als vorheriges Datum!'
      },
    })
  },
  async created() {
    await this.fetchData()
  },
  setup() {
    const pensumregel = [
      { value: true, label: '10/16 Regel' },
      { value: false, label: 'Schulstufenregel' },
    ]
    const obhutplan = [
      { value: 'AOSP', label: 'Alleinige Obhut: Standardbetreuungsplan' },
      { value: 'GOSP', label: 'Alternierende Obhut: Standardbetreuungsplan' },
      { value: 'GOEP', label: 'Alternierende Obhut: Erweiterter Betreuungsplan in Konvention' },
      { value: 'GOSK', label: 'Alternierende Obhut: Schulstufen mit erweitertem Betreuungsplan in Konvention' },
      { value: 'VOIK', label: 'Verteilte Obhut: Gegenseitige Absprache, allfällige Details In Konvention' },
    ]
    return {
      pensumregel,
      obhutplan,
    }
  },
  methods: {
    async fetchData() {
      const r = await dos.getDossier(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data[0]
        this.data.KostenProzUngedecktVater = this.data.KostenProzUngedecktVater === null ? 50 : this.data.KostenProzUngedecktVater
        this.erziehungsgutschriftGeteilt = this.data.Erziehungsgutschrift === 1
        this.WohnKindPauschal = this.data.WohnKindPauschal > 0
        this.erziehungsgutschriftMutter = this.data.Erziehungsgutschrift === 2
      }
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      this.data.WohnKindPauschal = event.target.value // eslint-disable-line
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async setWohnKindPauschal() {
      this.data.WohnKindPauschal = this.WohnKindPauschal ? this.data.WohnKindPauschal : 0
    },
    async setObhutplan() {
      this.data.GeteilteObhut = !(this.data.Obhutplan === 'AOSP' || this.data.Obhutplan === 'VOIK')
      this.data.MitBetreuungZeitbereichen = this.data.Obhutplan === 'GOSK'
      this.data.MitBetreuungZeitPlan = this.data.Obhutplan === 'GOEP' || this.data.Obhutplan === 'GOSK'
      this.data.IstObhutplanVater = this.data.AnspruchElternID === 2 ? 1 : 2
      // this.data.IstObhutplanVater = this.data.Obhutplan === 'AOSP' || this.data.Obhutplan === 'GOSP'
    },
    async save_changes(showToast) {
      this.data.KostenProzUngedecktMutter = 100 - this.data.KostenProzUngedecktVater
      this.data.Erziehungsgutschrift = this.erziehungsgutschriftGeteilt === true ? 1 : this.erziehungsgutschriftMutter === true ? 2 : 3 // eslint-disable-line
      // this.data.WohnKindPauschal = this.WohnKindPauschal ? this.data.WohnKindPauschal : 0
      await dos.putDossier(this.data)

      if (showToast) this.$root.showToast('Speichern', 'Kinderbelange erfolgreich gespeichert!')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
