<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <b-tabs v-model="activeTab" @activate-tab="onTabChange"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Allgemein</span>
        </template>
        <allgemein
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ActivityIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Unterhalt</span>
        </template>
        <unterhalt
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $root.AnzahlKinder > 1 ? 'Eltern' : 'Paar' }}</span>
        </template>
        <paar src="gd" class="mt-2 pt-75" />
      </b-tab>

      <b-tab v-if="data.MitKind && data.AnzahlKinder > 0">
        <template #title>
          <feather-icon
            icon="BookIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $root.AnzahlKinder > 1 ? 'Kinderbelange' : 'Kindbelange' }}</span>
        </template>
        <kindbelange class="mt-2 pt-75" />
      </b-tab>

      <b-tab v-if="(data.MitKind && data.AnzahlKinder > 0)">
        <template #title>
          <feather-icon
            icon="UserPlusIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <title>HELLO</title>
          <span class="d-none d-sm-inline">{{ $root.AnzahlKinder > 1 ? 'Kinder' : 'Kind' }}</span>
        </template>
        <kind src="gd" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import dos from '@/service/dossier'
import Allgemein from './Allgemein.vue'
import Unterhalt from './Unterhalt.vue'
import Paar from './Paar.vue'
import Kindbelange from './Kindbelange.vue'
import Kind from './Kind.vue'

export default {
  components: {
    BTab,
    BTabs,

    Allgemein,
    Unterhalt,
    Paar,
    Kindbelange,
    Kind,
  },
  data() {
    return {
      data: null,
      activeTab: 0,
    }
  },
  async mounted() {
    this.$root.$refs.GRUNDDATEN = this
    // this.$eventBus.$on('dataGrunddatenSaved', () => { this.fetchData() })
    // window.hasFormDataChanged = false
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const r = await dos.getDossier(this.$root.currDosID)
      if (r !== null && r.data !== null && r.data.length === 1) this.data = r.data[0]
    },
    // async onTabChange(newIx, curIx, event) {
    // IMPORTANT: TO MAKE THE AUTOSAVE WORK, WINDOW.SAVEFORMDATA MUST MERELY BE SET IN THE FIRST TAB-FORM, OTHERWISE, IT'S NOT DEFINED WHICH FUNCTION-POINTER IS SET...
    async onTabChange(newIx, curIx) {
      // let hasChanges = false
      // if (curIx === 0 && this.$root.$refs.ALLGEMEIN.hasFormDataChanged()) hasChanges = true
      // else if (curIx === 1 && this.$root.$refs.UNTERHALT.hasFormDataChanged()) hasChanges = true
      // else if (curIx === 2 && this.$root.$refs.PAAR.hasFormDataChanged()) hasChanges = true
      // else if (curIx === 3 && this.$root.$refs.KINDBELANGE.hasFormDataChanged()) hasChanges = true
      // else if (curIx === 4 && this.$root.$refs.KIND.hasFormDataChanged()) hasChanges = true
      // console.log('Changes: ' + hasChanges)

      if (curIx === 0) await this.$root.$refs.ALLGEMEIN.save_changes(false)
      else if (curIx === 1) await this.$root.$refs.UNTERHALT.save_changes(false)
      else if (curIx === 2) await this.$root.$refs.PAAR.save_changes(false)
      else if (curIx === 3) await this.$root.$refs.KINDBELANGE.save_changes(false)
      else if (curIx === 4) await this.$root.$refs.KIND.save_changes(false)

      if (newIx === 0) {
        window.saveFormData = this.$root.$refs.ALLGEMEIN.save_changes
        await this.$root.$refs.ALLGEMEIN.fetchData()
      } else if (newIx === 1) {
        window.saveFormData = this.$root.$refs.UNTERHALT.save_changes
        await this.$root.$refs.UNTERHALT.fetchData()
      } else if (newIx === 2) {
        window.saveFormData = this.$root.$refs.PAAR.save_changes
        await this.$root.$refs.PAAR.fetchData()
      } else if (newIx === 3) {
        window.saveFormData = this.$root.$refs.KINDBELANGE.save_changes
        await this.$root.$refs.KINDBELANGE.fetchData()
      } else if (newIx === 4) {
        window.saveFormData = this.$root.$refs.KIND.save_changes
        await this.$root.$refs.KIND.fetchData()
      }
      this.activeTab = newIx

      // if (window.hasFormDataChanged) {
      //   event.preventDefault()
      //   const ok = await this.$root.msgBoxConfirm('Sollen die Änderungen verworfen werden?')
      //   if (ok) {
      //     await this.$eventBus.$emit('dataGrunddatenSaved') // send event to all components which need to reload the data...
      //     await this.setIsDirty(curIx)
      //     this.activeTab = newIx
      //   }
      // } else await this.setIsDirty(curIx)
    },
    // async setIsDirty(curIx) {
    //   window.hasFormDataChanged = false
    //   if (curIx === 0) this.$root.$refs.ALLGEMEIN.isDirty = false
    //   else if (curIx === 1) this.$root.$refs.UNTERHALT.isDirty = false
    //   else if (curIx === 2) this.$root.$refs.PAAR.isDirty = false
    //   else if (curIx === 3) this.$root.$refs.KINDBELANGE.isDirty = false
    //   else if (curIx === 4) this.$root.$refs.KIND.isDirty = false
    // },
  },
  // beforeDestroy() {
  //   this.$eventBus.$off('dataGrunddatenSaved')
  // },
}
</script>

<style>

</style>
